import React from 'react';
import { useStaticQuery, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { breakpoints } from '../styles/Variables.js';
import { Col, Col_2, Container, Row } from '../styles/common/Layout.js';
import { H1Box } from '../styles/common/Element.js';

const { bpMedium, bpLarge, bpXlarge } = breakpoints;

const ContainerMargin = styled(Container)`
  margin-bottom: 30px;
  padding-top: 30px;
`;

const RowMargin = styled(Row)`
  margin-bottom: 30px;
`;

export default function SectionInfo() {
  const data = useStaticQuery(graphql`
    query TeamQuery {
      team: sanityStoreSettings {
        name
        imageTeam {
          asset {
            fluid {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <ContainerMargin as="section" id="infos">
        <Row>
          <Col>
            <H1Box>#OHNE UNS IST'S STILL</H1Box>
          </Col>
        </Row>
        <RowMargin>
          <Col>
            <p>Die Kulturgesichter sind die zahlreichen Initiativen einzelner Städte und Kreise in Deutschlands die hier gesammelt auftreten. Am Anfang standen die Kollegen aus Bremen die mit Ihrer Idee schnell weitere Mitstreiter gefunden haben. Wer oder Was verbrigt sich hinter den Kulturgesichtern?
            </p>
            <p>Die Kulturgesichter sind die zahlreichen Initiativen einzelner Städte und Kreise in Deutschlands die hier gesammelt auftreten. Am Anfang standen die Kollegen aus Bremen die mit Ihrer Idee schnell weitere Mitstreiter gefunden haben. Wer oder Was verbrigt sich hinter den Kulturgesichtern?</p>
            <p>Die Veranstaltungsbranche – der Kollateralschaden von Covid 19. Für viele von uns bedeutet die Pandemie von heute auf morgen, keine Jobs, keine Aufträge und keine Perspektive. Keine Unterstützung von Seiten der Regierung.</p>
          </Col>
          <Col>
            <p>Wir möchten Aufmerksamkeit für unsere Branche und den sterbenden Existenzen ein Gesicht geben. Daher planen wir eine großangelegte Poster Aktion um auf die anhaltende, sehr prekäre Lage und die Stille in der Veranstaltungsbranche aufmerksam zu machen. Aus Solidarität haben sich verschiedene prominente Künstler dazu bereit erklärt, auch Ihre Gesichter für unser Projekt zur Verfügung zu stellen und geben gemeinsam mit uns der Kulturbranche ein Gesicht.</p>
          </Col>
        </RowMargin>
        <Row>
          <Col><H1Box>WIR BENÖTIGEN EUREN SUPPORT</H1Box></Col>
        </Row>
        <RowMargin>
          <Col>

            <p>Als ein Hilferuf der Veranstaltungsbranche planen und realisieren wir die Aktionen komplett ehrenamtlich mit dem einzigen Ziel, unsere Existenzen zu retten indem wir aufklären WER wir sind und WAS wir machen. <strong>Denn #Ohne uns ist's still!</strong></p>
          </Col>
        </RowMargin>
        <Row>
          <Col>
            <H1Box>WER WIR ALLES SIND</H1Box>
            <p>Veranstalter // Eventagenturen // Managements // Light-Designer // Bühnenbauer // Bühnenausstatter // Rigger // Veranstaltungstechniker // Bühnenmeister // Caterer // Veranstaltungsausstatter // Musical-Darsteller // Merchandiser // Nightlinerfirmen // Busfahrer // Pyrotechniker // Schauspieler // Sänger // Theaterbetreiber // Festival-Veranstalter // Plakatierer // Ticketverkäufer // Clubbetreiber // Discothekenbetreiber // DJ‘s // Künstler // Werbeagenturen // Druckereien // Freelancer // Bühnenbildner // Regisseure // Fotografen // Schausteller // Musiker // Messebetreiber // Eventmanager // Eventmarketer // Eventplaner // Solo-Selbstständige //  Grafiker // Hotelbetreiber // Ticketingfirmen // Busunternehmer // Mietwagen-Verleiher // Hallenbetreiber // Securitymitarbeiter // Stagemanager // Booker // Eventfotografen // Freiberufler // Garderobiere // Gastromitarbeiter // Kinobetreiber // Video-Operator und sehr viele mehr.</p>
          </Col>
        </Row>
      </ContainerMargin>
    </>
  );
}
